.Home {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.Home-Banner {
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 435px;
}
.Home-image {
  width: calc(400 / 1920 * 100vw);
  cursor: pointer;
}

.Home-Banner {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
}

.Home-Banner-Image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  z-index: 0;
  object-fit: cover;
}

.Home-Banner-Image.active {
  opacity: 1;
}

.Home-Banner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  z-index: 1;
}

.Home-Banner-title {
  color: #77e6ff;
  font-size: 136px;
  font-family: "Helvetica", sans-serif;
  text-shadow: 4px 3px 0 #fff500, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.Home-Banner-contents {
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  font-size: 33px;
  font-weight: 600;
  text-align: center;
}

.Home-container {
  display: flex;
  flex-direction: column;
  background-color: #fff7f7;
  border-radius: 24px 24px 0px 0px;
  padding-top: 24px;
  border: 2px solid #000;
  max-width: 900px;
  margin-top: 45px;
}

.Home-container-TitleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c4bcfb;
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  padding: 24px;
}

.Home-Banner-chain {
  margin-top: 24px;
}

.Home-container-Title {
  font-size: 32px;
  font-weight: 500;
  font-family: "Graduate";
}

.Home-container-Contents {
  font-size: 19px;
  font-weight: 500;
}
.Home-container-Contents-Container {
  display: flex;
}

.Home-container-Contents-Container div:first-child {
  border-right: 2px solid #000;
  display: flex;
}

.Home-container-Contents-Container div:nth-child(2) {
  display: flex;
  padding: 12px 24px;
}

.Home-container-Community {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  border-top: 2px solid black;
}

.Home-container-Community-Item {
  width: 36px;
  height: 36px;
}

.Home-container-Community-Item img {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Helvetica";
  src: url("/public/fonts/Helvetica.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@media (max-width: 1280px) {
  .main-text {
    width: calc(1440 / 1280 * 100vw);
  }
}

@media (max-width: 900px) {
  .main-text {
    width: calc(1440 / 900 * 100vw);
  }
  .Home-container {
    width: 90%;
  }
  .Home-Banner-title {
    font-size: 72px;
  }
  .Home-Banner-contents {
    font-size: 24px;
  }
  .Base_Logo {
    width: 125px;
  }
  .Home-container-Contents-Container div:first-child {
    justify-content: center;
  }
  .Home-container-Contents {
    font-size: 18px;
  }
}

@media (max-width: 700px) {
  .Home-container-Contents-Container {
    flex-direction: column;
  }
  .Home-container-Contents-Container div:first-child {
    border-right: none;
  }
  .Home-container-Contents-Container div:nth-child(2) {
    border-top: 2px solid #000;
  }
  .Home-container-Title {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .main-text {
    width: calc(1440 / 500 * 70vw);
  }
}
