.Footer {
  background-color: #000; /* 검은 배경색 */
  color: #fff; /* 흰색 글자 */
  padding: 36px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(400 / 1920 * 100vw);
}

.Footer-container {
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: center;
  gap: 4.5rem;
}

.Footer-logo {
  height: 40px;
  /* 로고 이미지 높이 설정 */
}

.Footer-nav {
  display: flex;
  gap: 2rem;
}

.Footer-nav button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  transition: color 0.3s ease;
  font-family: "Montserrat";
}

.Footer-navbar-item {
  font-size: 16px;
  font-weight: 500;
}

.Footer-nav button:hover {
  color: #ccc;
}

.subscript {
  font-size: 8px;
  margin-left: 5px;
  position: relative;
  bottom: -16px;
  right: 35px;
}

@media (width < 900px) {
  .Footer-container {
    flex-direction: column;
  }
  .Footer-nav {
    align-items: center;
    margin-bottom: 24px;
  }
  .Footer {
    padding: 120px 0;
  }
}

@media (max-width: 700px) {
  .Footer-container {
    gap: 2rem;
    padding: 18px;
  }
  .Footer-nav {
    flex-direction: column;
  }
  .Footer {
    height: calc(850 / 700 * 100vw);
    padding: 100px 0;
  }
}
