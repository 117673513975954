.Tokenomics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Tokenomics-container {
  display: flex;
  flex-direction: column;
  background-color: #c4c5f4;
  border-radius: 24px 24px 0px 0px;
  padding-top: 24px;
  border: 2px solid #000;
  max-width: 900px;
  margin-top: 45px;
}

.Tokenomics-container-TitleContainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  background-color: #fff;
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  padding: 24px;
}

.Tokenomics-container-Title {
  font-size: 18px;
  font-weight: 700;
}

.Tokenomics-container-Contents-Container {
  display: flex;
}

.Tokenomics-container-Contents-Right {
  display: flex;
  flex-direction: column;
}

.Tokenomics-container-Contents-Right-Main {
  padding: 36px;
  border: 2px solid #000;
  border-top: none;
  border-left: none;
  border-right: none;
  height: 30%;
}

.Tokenomics-container-Contents-Right-Main div span {
  text-align: center;
  font-family: "Shrikhand", serif;
  font-size: 26px;
}

.Tokenomics-container-Contents-Right-Main p {
  font-size: 19px;
  font-weight: 600;
  margin: 52px 0;
  line-height: 1.3;
}

.Tokenomics-container-Contents-Right-Sub {
  background-color: #fff7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 48px;
  gap: 12px;
  height: 70%;
}

.Tokenomics-container-Contents-Right-Sub-Title {
  font-size: 34px;
  font-weight: 700;
  text-align: center;
}

.Tokenomics-container-Contents-Right-Sub-Details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
  margin: 24px 0;
}

.Tokenomics-container-Contents-Right-Sub-Details div span {
  font-weight: bold;
  margin-right: 4px;
}

.Tokenomics-container-Contents-Right-Sub-Details div {
  font-size: 23px;
}

.Tokenomics-container-Contents-Left {
  display: flex;
  flex-direction: column;
}

.Tokenomics-container-Contents-Left-Main {
  background-color: #fff7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  border-top: none;
  border-left: none;
  border-bottom: none;
  padding: 16px;
  height: 100%;
}

.Tokenomics-container-Contents-Left-Main p {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.Tokenomics-container-Contents-Left-Sub {
  display: flex;
}

.Tokenomics-container-Footer {
  position: relative;
  padding: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border-top: 2px solid black;
}

/* .Tokenomics-container-Footer span {
  font-size: 28px;
  font-family: "Graduate";
  font-weight: 500;
} */

.popup {
  position: absolute;
  right: -80%;
  bottom: 120%;
  z-index: 10;
  border: 2px solid black;
  background-color: #fff7f7;
}

/* .CA {
  font-size: 14px;
} */

@media (max-width: 900px) {
  .Tokenomics-container {
    width: 90%;
  }
  .Tokenomics-container-Contents-Right-Main {
    height: fit-content;
  }
  .Tokenomics-container-Contents-Right-Sub {
    padding: 18px;
  }
  .Tokenomics-container-Contents-Right-Sub-Details {
    grid-template-columns: repeat(1, 1fr);
  }
  .CA {
    font-size: 12px;
  }
}

@media (max-width: 820px) {
  .Tokenomics-container-Contents-Container {
    flex-direction: column;
  }
  .Tokenomics-container-Contents-Left-Main {
    border: none;
    border-bottom: 2px solid #000;
    height: fit-content;
  }
}

@media (max-width: 500px) {
  .Tokenomics-container-Contents-Right-Sub-Title {
    font-size: 24px;
  }
  .Tokenomics-container-Contents-Right-Sub-Details div {
    font-size: 18px;
  }
  .Tokenomics-container-Contents-Right-Main div span {
    font-size: 22px;
  }
}
