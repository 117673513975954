.Main {
  overflow: hidden;
  background-color: #fff;
  font-family: "Montserrat";
}

.Main-images {
  display: flex;
  justify-content: center;
}

.scroll-to-top {
  display: flex;
  position: fixed;
  right: 48px;
  bottom: 48px;
  background-color: #c9c9c9;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  z-index: 999;
}

.eclipse-left {
  background-image: url("/public/images/eclipse.png");
  background-repeat: no-repeat;
  background-position: left center;
  display: flex;
  position: absolute;
  left: 50px;
  width: 10px;
  height: 29px;
  align-items: center;
}

.eclipse-right {
  background-image: url("/public/images/eclipse.png");
  background-repeat: no-repeat;
  background-position: left center;
  display: flex;
  position: absolute;
  right: 50px;
  width: 10px;
  height: 29px;
  align-items: center;
  transform: rotate(90deg);
}

@media (width < 1100px) {
  .Main-images {
    display: flex;
    justify-content: center;
  }

  .Main-images img {
    width: 200px;
  }

  .titlemodel {
    padding-bottom: calc(45 / 850 * 100vw);
    background-size: auto calc(65 / 850 * 100vw);
  }
}

@media (max-width: 900px) {
  .scroll-to-top {
    right: 30px;
    bottom: 85px;
  }
  .eclipse-left {
    left: 25px;
  }
  .eclipse-right {
    right: 25px;
  }
}
