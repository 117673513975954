.FAQ {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.FAQ-container {
  display: flex;
  flex-direction: column;
  background-color: #c4bcfb;
  border-radius: 24px 24px 0px 0px;
  padding-top: 24px;
  border: 2px solid #000;
  width: 900px;
  margin: 45px 0px;
}

.FAQ-container-TitleContainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  background-color: #fff;
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  padding: 24px;
}

.FAQ-container-Title {
  font-size: 18px;
  font-weight: bold;
}

.FAQ-container-Contents {
  font-size: 19px;
  font-weight: 600;
}
.FAQ-container-Contents-Container {
  display: flex;
}

.FAQ-container-Contents-Container div:first-child {
  border-right: 2px solid #000;
  display: flex;
}

.FAQ-container-Contents-Container div:nth-child(2) {
  display: flex;
  padding: 12px 24px;
}

li {
  margin-left: 12px;
}

li::marker {
  font-size: 16px;
}

@media (width < 900px) {
  .FAQ-container {
    width: 90%;
  }
}
