.About {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.About-container {
  display: flex;
  flex-direction: column;
  background-color: #32e412;
  border-radius: 24px 24px 0px 0px;
  padding-top: 24px;
  border: 2px solid #000;
  max-width: 900px;
  margin-top: 45px;
}

.About-container-TitleContainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  background-color: #fff;
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  padding: 24px;
}

.About-container-Title {
  font-size: 18px;
  font-weight: 700;
}

.About-container-Contents-Container {
  display: flex;
}

.About-container-Contents-Left {
  display: flex;
  flex-direction: column;
  width: 65%;
}

.About-container-Contents-Left-Main {
  background-color: #fff;
  padding: 36px;
  border: 2px solid #000;
  border-top: none;
  border-left: none;
  border-bottom: none;
}

.About-container-Contents-Left-Main div span {
  text-align: center;
}

.About-container-Contents-Left-Main div span:nth-child(1) {
  color: #000;
  font-size: 43px;
  font-family: "Graduate";
  font-weight: 600;
}

.About-container-Contents-Left-Main div span:nth-child(2) {
  color: #000;
  font-size: 90px;
  font-family: "Shrikhand", serif;
}

.About-container-Contents-Left-Main div span:nth-child(3) {
  color: #000;
  font-size: 43px;
  font-family: "Graduate";
  font-weight: 600;
}

.About-container-Contents-Left-Main p {
  font-size: 19px;
  font-weight: 500;
  margin: 32px 0;
  line-height: 1.3;
}

.About-container-Contents-Left-Sub {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #000;
  border-left: none;
  border-right: none;
  padding: 24px 36px;
  height: 20%;
}

.About-container-Contents-Right {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.About-container-Contents-Right-Main {
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 8px;
}

.About-container-Contents-Right-Sub {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  background-color: #f5f5f5;
}

.About-container-Footer {
  background-color: #c4eabd;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.About-container-Footer span {
  font-size: 28px;
  font-family: "Graduate";
  font-weight: 500;
}

@media (max-width: 900px) {
  .About-container {
    width: 90%;
  }
  .About-container-Contents-Left-Main div span:nth-child(1) {
    font-size: 32px;
  }
  .About-container-Contents-Left-Main div span:nth-child(2) {
    font-size: 52px;
  }
  .About-container-Contents-Left-Main div span:nth-child(3) {
    font-size: 32px;
  }
  .About-container-Contents-Left-Main p {
    font-size: 18px;
  }
  .buy_btn {
    width: 300px;
  }
  .About-container-Contents-Left-Sub {
    padding: 18px;
  }
}

@media (max-width: 700px) {
  .Cat_video {
    width: 250px;
  }
  .About-container-Contents-Left-Main p {
    margin: 24px 0;
  }
  .About-container-Footer span {
    text-align: center;
  }
  .About-container-Contents-Container {
    flex-direction: column;
  }
  .About-container-Contents-Right-Sub {
    justify-content: center;
  }
  .About-container-Contents-Left-Main {
    height: fit-content;
    border: none;
    border-bottom: 2px solid #000;
  }
  .About-container-Contents-Left {
    width: 100%;
  }
  .About-container-Contents-Right {
    width: 100%;
  }
}
