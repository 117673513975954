.Mission {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Mission-container {
  display: flex;
  flex-direction: column;
  background-color: #ff8a60;
  border-radius: 24px 24px 0px 0px;
  padding-top: 24px;
  border: 2px solid #000;
  max-width: 900px;
  margin-top: 45px;
}

.Mission-container-TitleContainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  background-color: #fff;
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  padding: 24px;
}

.Mission-container-Title {
  font-size: 18px;
  font-weight: 700;
}

.Mission-container-Contents-Container {
  display: flex;
  border-bottom: 2px solid #000;
}

.Mission-container-Contents-Left {
  display: flex;
  flex-direction: column;
}

.Mission-container-Contents-Left-Main {
  background-color: #fff7f7;
  padding: 12px 32px;
  border: 2px solid #000;
  border-top: none;
  border-left: none;
  height: 20%;
}

.Mission-container-Contents-Left-Main div span {
  text-align: center;
}

.Mission-container-Contents-Left-Main div span {
  color: #000;
  font-size: 72px;
  font-weight: 700;
  font-family: "Menlo";
}

.Mission-container-Contents-Left-Main p {
  font-size: 19px;
  font-weight: 600;
  margin: 52px 0;
  line-height: 1.3;
}

.Mission-container-Contents-Left-Sub {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid #000;
}

.Mission-container-Contents-Right {
  display: flex;
  flex-direction: column;
  max-width: 500px;
}

.Mission-container-Contents-Right-Main {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #000;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 26px 40px;
  height: 20%;
}

.Mission-container-Contents-Right-Main p {
  font-size: 31px;
  font-family: "Graduate";
  font-weight: 500;
  margin: 0;
}

.Mission-container-Contents-Right-Sub {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
}

.Mission-container-Contents-Right-Sub-Contents {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  height: 100%;
  border-left: none;
}

.Mission-container-Contents-Right-Sub-Contents p {
  font-size: 19px;
  font-weight: 500;
}

.Mission-container-Footer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.Mission-container-Footer-Title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: #fad3af;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}

.Mission-container-Footer-Title span {
  font-family: "Graduate";
  font-size: 21px;
}

.Mission-container-Footer-Contents {
  display: flex;
}

.Mission-container-Footer-Contents div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 151px;
  height: 134px;
  background-color: #fff;
  border: 1px solid #000;
  border-top: none;
  border-bottom: none;
}

.Mission-container-Footer-Contents div img {
  max-width: 100px;
}

@media (max-width: 900px) {
  .Mission-container {
    width: 90%;
  }
  .Mission-container-Contents-Right-Sub-Contents p {
    font-size: 18px;
  }
  .Mission-container-Contents-Right-Main {
    padding: 18px;
  }
}

@media (max-width: 700px) {
  .Mission-container-Contents-Container {
    flex-direction: column;
  }
  .Mission-container-Contents-Left-Sub {
    border-right: none;
  }
  .Mission-container-Contents-Left-Main {
    border-right: none;
  }
  .Mission-container-Contents-Right {
    max-width: none;
  }
  .Mission-container-Contents-Left-Main div span {
    font-size: 52px;
  }
  .Mission-container-Footer-Contents div img {
    max-width: 50px;
  }
  .Mission-container-Footer-Contents div {
    width: 100%;
    height: calc(100vw / 5);
  }
  .Mission-container-Contents-Right-Main p {
    font-size: 24px;
  }
}

@media (max-width: 643px) {
  .Mission-container-Contents-Right-Sub {
    height: fit-content;
  }
}

@media (max-width: 500px) {
  .mission_img {
    width: 100%;
  }
  .Mission-container-Contents-Left-Main div span {
    font-size: 42px;
  }
  .Mission-container-Contents-Right-Main p {
    text-align: center;
  }
}

@font-face {
  font-family: "Menlo";
  src: url("/public/fonts/Menlo.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
