.Reward {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Reward-container {
  display: flex;
  flex-direction: column;
  background-color: #ffc957;
  border-radius: 24px 24px 0px 0px;
  padding-top: 24px;
  border: 2px solid #000;
  width: 900px;
  margin-top: 45px;
}

.Reward-container-TitleContainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  background-color: #fff7f7;
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  padding: 24px;
}

.Reward-container-Title {
  font-size: 18px;
  font-weight: 700;
}

.Reward-container-Contents-Container {
  display: flex;
  flex-direction: column;
}

.Reward-container-Contents-Banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 42px;
  background-color: #fff0d0;
}

.Reward-container-Contents-Banner-Title p {
  font-size: 28px;
  font-weight: bold;
}

.Reward-container-Contents-Banner-Button {
  display: flex;
  padding: 12px 28px;
  background-color: #fff;
  box-shadow: 4px 4px #000;
}

.Reward-container-Contents-ImageContainer {
  display: flex;
}

.Reward-container-Contents-ImageContainer img {
  width: 298px;
}

.Reward-container-Contents-ImageContainer img:first-of-type {
  border: 2px solid #000;
  border-left: none;
}

.Reward-container-Contents-ImageContainer img:nth-of-type(2) {
  border: 2px solid #000;
  border-left: none;
}

.Reward-container-Contents-ImageContainer img:nth-of-type(3) {
  border: 2px solid #000;
  border-left: none;
}

.Reward-container-Contents-Footer {
  background-color: #fff;
  padding: 36px;
  font-size: 19px;
  font-weight: 500;
}

.Reward-container-Contents-Footer span:first-of-type {
  font-weight: bold;
}

.Reward-container-Contents-Footer span:last-of-type {
  font-weight: 300;
  font-style: italic;
}

@media (width < 900px) {
  .Reward-container {
    width: 90%;
  }
  .Reward-container-Contents-ImageContainer img {
    width: calc((90vw / 3) - 2px);
    height: calc((90vw / 3) - 2px);
  }
  .Reward-container-Contents-Banner-Title p {
    text-align: center;
  }
  .Reward-container-Contents-Footer p {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .Reward-container-Contents-Banner {
    flex-direction: column;
  }
}
