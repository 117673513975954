/* AudioPlayer.css */
.audio-player {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
  background-color: white;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.audio-player-image {
  width: 124px;
  height: auto;
  border-radius: 4px;
}

.audio-controller {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.audio-player-button {
  cursor: pointer;
  width: fit-content;
  opacity: 0.7;
}

.audio-player-button:hover {
  opacity: 0.9;
  transform: scale(1.1);
}

@media (width < 900px) {
  .audio-player {
    bottom: 0;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0%);
    top: auto;
    right: auto;
    border-radius: 0px;
    padding: 8px 4px;
    border-top: 2px solid #000;
  }

  .audio-player-image {
    width: 54px;
  }
}
