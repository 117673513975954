.Community {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Community-container {
  display: flex;
  flex-direction: column;
  background-color: #cfedde;
  border-radius: 24px 24px 0px 0px;
  padding-top: 24px;
  border: 2px solid #000;
  max-width: 900px;
  margin-top: 45px;
}

.Community-container-TitleContainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  background-color: #fff;
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  padding: 24px;
}

.Community-container-Title {
  font-size: 18px;
  font-weight: 700;
}

.Community-container-Contents-Container {
  display: flex;
  flex-direction: column;
}

.Community-container-Contents-ImageContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

.Community-container-Contents-ImageContainer img {
  width: 100%;
  filter: brightness(85%);
}

.Community-container-Contents-Absolute {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: bold;
}

.Community-container-Contents-Absolute span {
  font-size: 50px;
  text-shadow: 0px 4px 6px #000;
}

.Community-container-Contents-Absolute p {
  font-size: 18px;
  text-align: center;
  text-shadow: 0px 4px 6px #000;
}

.Community-container-Contents-Footer {
  background-color: #fff;
  padding: 24px 36px;
}

@media (width < 900px) {
  .Community-container {
    width: 90%;
  }
}

@media (max-width: 700px) {
  .Community-container-Contents-Absolute {
    font-weight: 400;
    width: 100%;
  }
  .Community-container-Contents-Absolute span {
    font-size: 32px;
  }
  .Community-container-Contents-Absolute p {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .Community-container-Contents-Absolute span {
    font-size: 24px;
  }
}
