.HowToBuy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.HowToBuy-container {
  display: flex;
  flex-direction: column;
  background-color: #f872ab;
  border-radius: 24px 24px 0px 0px;
  padding-top: 24px;
  border: 2px solid #000;
  width: 900px;
  margin-top: 45px;
}

.HowToBuy-container-TitleContainer {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  background-color: #fff;
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  padding: 24px;
}

.HowToBuy-container-Title {
  font-size: 18px;
  font-weight: 700;
}

.HowToBuy-container-Contents-Container {
  display: flex;
}

.HowToBuy-container-Contents-Left {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.HowToBuy-container-Contents-Left-Title {
  font-size: 18px;
  font-weight: bold;
}

.HowToBuy-container-Contents-Left div:first-of-type {
  background-color: #3489ca;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
}

.HowToBuy-container-Contents-Left div:first-of-type p {
  font-size: 18px;
  font-weight: bold;
}

.HowToBuy-container-Contents-Left-Second {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-top: 2px solid #000;
  padding: 36px;
  gap: 14px;
  height: 50%;
  text-align: center;
}

.HowToBuy-container-Contents-Left-Second p {
  font-size: 16px;
  font-weight: 500;
}
.HowToBuy-container-Contents-Left-Second a {
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}

.HowToBuy-container-Contents-Left-NumberBox {
  background-color: #3489ca;
  width: 23px;
  height: 23px;
  padding: 7px !important;
  border-radius: 50%;
}
.HowToBuy-container-Contents-Left-NumberBox span {
  font-size: 22px;
  font-weight: bold;
}

.HowToBuy-container-Contents-Right {
  width: 50%;
  border-left: 2px solid #000;
}

@media (width < 900px) {
  .HowToBuy-container {
    width: 90%;
  }
}

@media (max-width: 700px) {
  .HowToBuy-container-Contents-Container {
    flex-direction: column;
  }
  .HowToBuy-container-Contents-Left {
    width: 100%;
  }
  .HowToBuy-container-Contents-Right {
    width: 100%;
    border: none;
    border-top: 2px solid #000;
    border-right: 2px solid #000;
  }
  .HowToBuy-container-Contents-Left-Second {
    height: fit-content;
  }
}
