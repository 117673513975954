.Header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 1rem 2rem;
  z-index: 1;
  width: 100%;
  border: 2px solid #000;
  border-bottom: none;
}

.Header-navbar-start {
  display: flex;
  gap: 12px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}
.Header-navbar-start-icon {
  width: 6.64rem;
}
.Header-navcontainer {
  display: flex;
}
.Header-navbar-center {
  display: flex;
  align-items: center;
}

.Header-navbar-center :where(li) {
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  font-size: 1rem;
}
.Header-navbar-end {
  display: flex;
  gap: 1.25rem;
  cursor: pointer;
  align-items: center;
}
.Header-navbar-btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: #fff;
  padding: 8px 16px;
  border: 1px solid #000;
  box-shadow: 2px 2px 1px #000;
  color: #000;
  text-align: center;
  font-weight: 700;
}

.Header-navbar-btn:hover {
  color: #fff;
  background-color: #000;
}

.Header-menu {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: max-content;
}

.Header-Logo-Container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.Header-Logo-Text {
  font-size: 15px;
  font-weight: 600;
}

.Header-navbar-item {
  display: flex;
  align-items: center;
  font-size: 15px;
  white-space: pre;
  font-weight: 500;
}

.Header-navbar-item:hover {
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}

.Header-navbar-Link {
  display: flex;
  gap: 5rem;
  width: 40%;
  justify-content: center;
  align-items: center;
}
.Header-end-menu {
  display: none;
}

@media (max-width: 600px) {
  .Header-navbar-Link {
    margin: 0 !important;
  }

  .Header-navbar-btn {
    padding: 3px !important;
    height: 20px;
    font-size: 12px;
    margin-bottom: 2px;
  }

  .Header-navbar-start {
    justify-content: space-between;
  }
}
@media (max-width: 900px) {
  .Header {
    justify-content: space-between;
    width: auto;
  }

  .Header-menu {
    position: absolute;
    top: 7rem;
    right: 0;
    overflow: hidden;
    flex-direction: column;
    transition: 0.3s ease-in-out;
    transform: scale(0);
    transform-origin: top;
  }
  .Header-menu-open {
    height: max-content;
    transform: scale(1);
  }
  .Header-end-menu {
    display: flex;
    justify-self: flex-end;
    align-self: center;
    padding: 0 1rem;
  }
}
